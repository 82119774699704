import './App.css';
import React, { useEffect, useState } from "react";

import Header from './Components/Header';
import Navbar from './Components/Navbar';
import {firebaseAnalytics} from './firebaseConfig'

function App() {
  useEffect(()=>{
    firebaseAnalytics.logEvent("visited");
  },[])
  return (
    <div className="App">
      {/* <Navbar/> */}
     <Header/>
    </div>
  );
}

export default App;

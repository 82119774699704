import React from "react";
import "../styles/Navbar/Navbar.css";
import logo from "../assets/logo.png";
import talents from "../assets/Talents.png";

const Navbar = () => {
  return (
    <div className="navbarContainer">
      <img src={logo} className="logo"/>
      <img src={talents} className="talents" />
      <button>
        <a href="https://talents-webapp.web.app/logIn" target="_blank">Sign in</a>
      </button>
    </div>
  );
};

export default Navbar;

import React from "react";
import "../styles/Header.css";
import Navbar from "./Navbar";
import mainBack from "../assets/bag_with.png";

const Header = () => {
  return (
    <div className="headerContainer">
      <Navbar />
      <div className="mainContainer">
        <div className="leftSide">
          {/* <h1>first side</h1> */}
          <img src={mainBack} />
        </div>
        <div className="rightSide">
          <h1>Match the right employee with the right project.</h1>
          <p>
            We help you understand the skillset of your stuff and find the best
            talent for your ideas.
          </p>
          <p className="opacityp">Take your organizations skill management to the next level.</p>

          <button>Sign up for free</button>
        </div>
      </div>
    </div>
  );
};

export default Header;

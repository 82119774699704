import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCWOu2Nb17aSho4bv9bq6XCiBOmXe6Js1M",
    authDomain: "talents-homepage.firebaseapp.com",
    projectId: "talents-homepage",
    storageBucket: "talents-homepage.appspot.com",
    messagingSenderId: "657134226820",
    appId: "1:657134226820:web:984afc94a3f760f73361d1",
    measurementId: "G-2CW83TR1PL"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const firebaseAnalytics = firebase.analytics();